.light {
    --theme-color-1: #FFFFFF;
    --theme-color-2: #000;
    --theme-color-3: #D9D9D9;
    --theme-color-4: #000;
    --theme-color-5: #fff;
    --theme-color-6: #3B82F6;
    --theme-color-7: #4B5563;
    --theme-color-8: #111827;
    --theme-color-9: #D9D9D9;
}
.dark {
    --theme-color-1: #000;
    --theme-color-2: #fff;
    --theme-color-3: #1A1A1A;
    --theme-color-4: #000;
    --theme-color-5: #fff;
    --theme-color-6: #3B82F6;
    --theme-color-7: #4B5563;
    --theme-color-8: #a1a1a1;
    --theme-color-9: #D9D9D9;
}